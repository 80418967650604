<template>
    <div id="manageSalesHoursContainer" class="sales-hours-container">
        <select-shop id="manageSalesHoursSelectShop" class="select-shop-container" :loadingButton="loadingButton"
            v-on:getData="getManageSaleHours" />
        <div id="manageSalesHoursPermissionsWrapper" v-if="isPermission">
            <CCard id="manageSalesHoursCard" class="shadow-sm">
                <CCardBody id="manageSalesHoursCardBody">

                    <CAlert v-if="status === 'SUCCESS'" :color="statusColor" :show.sync="synchSuccessCounter" closeButton >
                       {{ synchStatusText }}  
                        <!-- <CProgress :max="5"  :value="synchSuccessCounter" height="5px"  color="success" animate /> -->
                    </CAlert>
                    <CAlert v-else :color="statusColor" :show.sync="synchSuccessCounter"  >
                       {{ synchStatusText }} ...
                        <CProgress :max="10" :value="synchSuccessCounter" height="10px" :color="statusColor" animate striped />
                    </CAlert>
                    <CAlert v-if="status === 'fail'" :color="statusColor" :show.sync="synchSuccessCounter" closeButton >
                       {{ synchStatusText }} 
                       <p style="font-size: small;">{{synchFailDescription}}</p>
                    </CAlert>

                    <CAlert color="success" v-if="isSuccess === true" :show="Number(3)">
                        {{ $t('saveSuccess') }}
                    </CAlert>
                    <CAlert color="danger" v-if="isDeleteSuccess === true" :show="Number(3)">
                        {{ $t('deleteSuccess') }}
                    </CAlert>
                    <div id="manageSalesHoursHeaderRow" class="row mb-3">
                        <div id="manageSalesHoursHeaderTitle"
                            class="col-md-10 col-sm-9 col-8 d-inline-flex align-items-center">
                            <h2 id="manageSalesHoursPageTitle" class="font-weight-normal">
                                <img src="/img/delivery/grab.png" class="rounded-circle"
                                    style="border-radius: 3px; width: 45px;" />
                                {{ $t("salesHoursManagement") }}  <span class="text-success cursor" @click="confirmSynchModal = true"> <i class="fa-solid fa-rotate"></i></span>

                            </h2>
                        </div>
                        <div id="manageSalesHoursAddButtonContainer" class="col-md-2 col-sm-9 col-4">
                            <CButton v-if="data.length < 3" id="manageSalesHoursAddButton" block color="success"
                                @click="addSalseHours">
                                {{ $t("addSalesHours") }}
                            </CButton>
                        </div>
                    </div>
                    <CAlert id="addSalesHoursInfoAlertBox" color="info" class="d-inline-block col-12 mt-0 mb-0">
                        <div class="d-flex">
                            <div class="flex-shrink-0">
                                <i class="fi fi-rr-info mr-1 text-info"></i>
                            </div>
                            <div class="flex-grow-1 ms-3 ml-2">
                                <p class="m-0">{{ $t('setSelling') }}</p>
                                <p class="m-0"> - {{ $t("txtSalesHoursSetting") }}</p>
                                <p class="m-0"> - {{ $t("txt2SalesHoursSetting") }}</p>
                                <p v-html="$t('txtSalesHoursSettingDes')" class="text-description text-muted"></p>
                            </div>
                        </div>
                    </CAlert>
                    <hr id="manageSalesHoursHeaderDivider" />
                    <div id="manageSalesHoursDataTableSection">
                        <CRow id="manageSalesHoursTableRow" class="mt-3">
                            <CCol id="manageSalesHoursTableColumn" sm="12" lg="12">
                                <DataTable id="manageSalesHoursTable" :fields="fields" :items="data" :loading="loading"
                                    border hover clickableRows style="white-space: nowrap; width: 100%;">
                                    <template #name="{ item }">
                                        <td class="py-3 text-dark font-weight-normal ">
                                            {{ item.name }}
                                        </td>
                                    </template>
                                    <template #action="{ item, index }">
                                        <td id="manageSalesHoursActionColumn" class="text-center" v-if="isEditData">
                                            <CRow>
                                                <CCol class="m-0 p-0">
                                                    <CButton v-if="!details.includes(item)"
                                                        id="manageSalesHoursEditIcon"
                                                        @click="toggleDetails(item, index)" color="info"
                                                        variant="outline">
                                                        <CIcon name="cil-color-border" size="xl" />
                                                    </CButton>
                                                    <CButton v-if="details.includes(item)" color="success"
                                                        variant="outline" @click="validateData(item, index)"
                                                        id="manageSalesHoursSaveIcon">
                                                        <CIcon name="cil-save" size="xl" />
                                                    </CButton>
                                                </CCol>
                                                <CCol class="m-0 p-0">
                                                    <CButton v-if="!details.includes(item)"
                                                        id="manageSalesHoursDeleteIcon" @click="confirmdelete(item)"
                                                        color="danger" variant="outline">
                                                        <CIcon name="cil-trash" size="xl" />
                                                    </CButton>
                                                    <CButton v-if="details.includes(item)" color="danger"
                                                        variant="outline" @click="toggleDetails(item, index)">
                                                        <CIcon name="cil-x" size="xl" id="manageSalesHoursHideIcon"
                                                            @click="toggleDetails(item, index)" />
                                                    </CButton>
                                                </CCol>
                                            </CRow>
                                        </td>
                                    </template>
                                    <template #details="{ item, index }">
                                        <CCollapse id="manageSalesHoursDetailsCollapse" :show="details.includes(item)">
                                            <CCardBody id="manageSalesHoursDetailsCardBody" border-color="light">
                                                <CRow>
                                                    <CCol id="editSalesHours-salesHoursLabel" class="col-form-label"
                                                        style="margin-top: 2%;" lg="2">
                                                        <h6>{{ $t('txtsalesHours') }}</h6>
                                                    </CCol>
                                                    <CCol id="editSalesHours-salesHoursInputColumn"
                                                        style="margin-top: 2%;" lg="10">
                                                        <CInput id="editSalesHours-salesHoursInput"
                                                            v-if="validateName(item.name)" v-model="item.name"
                                                            :placeholder="$t('fillSaleName')" :is-valid="validator"
                                                            :invalid-feedback="$t('fillSaleName')" />
                                                        <CInput id="editSalesHours-salesHoursInputAlt" v-else
                                                            v-model="item.name" :placeholder="$t('fillSaleName')" />
                                                    </CCol>
                                                </CRow>
                                                <hr />
                                                <h5 class="mt-4">{{ $t('salesHoursSetting') }}</h5>

                                                <div id="editSalesHours-scheduleContainer" class="schedule-container">
                                                    <div v-for="(day, dayIndex) in Object.keys(item.serviceHours)"
                                                        :key="dayIndex" :id="'editSalesHours-daySchedule-' + dayIndex"
                                                        class="day-schedule">
                                                        <CRow id="editSalesHours-dayRow"
                                                            class="justify-content-between m-1">
                                                            <CCol id="editSalesHours-dayCheckboxColumn" col="12" sm="2"
                                                                md="2" lg="3">
                                                                <div id="editSalesHours-dayHeader"
                                                                    class="day-header mt-2 ">
                                                                    <input id="editSalesHours-dayCheckbox"
                                                                        type="checkbox" class="mr-5 ml-3 m"
                                                                        v-model="item.serviceHours[day].enable" />
                                                                    <span class="text-dark font-weight-normal"> {{ $t(hoursName(day)) }}</span>
                                                                </div>
                                                            </CCol>
                                                            <CCol id="editSalesHours-timeSlotColumn" col="12" md="8"
                                                                lg="9" class="text-right">
                                                                <CRow
                                                                    v-for="(slot, slotIndex) in item.serviceHours[day].periods"
                                                                    :key="slotIndex" class="justify-content-end m-2">
                                                                    <CCol id="editSalesHours-startTimeColumn" col sm="4"
                                                                        md="4" lg="3" class="text-right">
                                                                        <vue-timepicker
                                                                            id="editSalesHours-startTimePicker"
                                                                            input-class="form-control"
                                                                            v-model="slot.startTime"
                                                                            :disabled="!item.serviceHours[day].enable"
                                                                            :min-time="slot.minTime"
                                                                            :max-time="slot.maxTime" />
                                                                    </CCol>
                                                                    <CCol id="editSalesHours-separatorColumn" col="1"
                                                                        md="1" lg="1" class="text-center">
                                                                        <span>-</span>
                                                                    </CCol>
                                                                    <CCol id="editSalesHours-endTimeColumn" col="5"
                                                                        md="4" lg="3" class="text-left">
                                                                        <vue-timepicker
                                                                            id="editSalesHours-endTimePicker"
                                                                            input-class="form-control"
                                                                            v-model="slot.endTime"
                                                                            :disabled="!item.serviceHours[day].enable"
                                                                            :min-time="slot.minTime"
                                                                            :max-time="slot.maxTime" />
                                                                    </CCol>
                                                                    <CCol id="editSalesHours-actionsColumn" col="1"
                                                                        md="1" lg="1" class="text-right">
                                                                        <button id="editSalesHours-addSlotButton"
                                                                            class="add-btn"
                                                                            @click="addSlot(item, index, day)"
                                                                            :disabled="!item.serviceHours[day].enable || item.serviceHours[day].periods.length >= maxSlots"
                                                                            v-if="slotIndex === 0">
                                                                            <img style="width: 25px;"
                                                                                src="/img/flat-color-icons--plus.png" />
                                                                        </button>
                                                                        <button v-else
                                                                            id="editSalesHours-removeSlotButton"
                                                                            class="delete-btn"
                                                                            @click="removeSlot(item, index, day, slotIndex)">
                                                                            <img src="/img/product/delete32.png"
                                                                                style="width: 30px;" />
                                                                        </button>
                                                                    </CCol>
                                                                </CRow>
                                                            </CCol>
                                                        </CRow>
                                                    </div>
                                                </div>
                                            </CCardBody>
                                            <br v-if="index + 1 === data.length "  />
                                            <br v-if="index +1  === data.length "/>
                                            <br v-if="index +1  === data.length "/>
                                            <br v-if="index + 1 === data.length " />


                                        </CCollapse>
                                    </template>

                                    <template #no-items-view>
                                        <div id="manageSalesHoursNoItemsView" class="text-center my-5">
                                            <h4 id="manageSalesHoursNoItemsText" style="color: #ced2d8;">
                                                {{ $t("noItem") }}
                                            </h4>
                                        </div>
                                    </template>

                                </DataTable>
                            </CCol>
                        </CRow>
                    </div>
                </CCardBody>
            </CCard>
            <CModal :show.sync="addSalesHoursModal" :title="namemodal" centered>
                <CRow>
                    <CCol md="4" sm="4" class="mt-2">{{ $t('txtsalesHours') }}</CCol>
                    <CCol md="8" sm="8">
                        <CAlert color="danger" v-if="saveErrorMsg" :show="Number(1)">
                            {{ saveErrorMsg }}
                        </CAlert>
                        <CInput v-if="validateSellingname" v-model="sellingname" :placeholder="$t('txtsalesHours')"
                            :is-valid="validator" :invalid-feedback="$t('validateSellingname')" />
                        <CInput v-else v-model="sellingname" :placeholder="$t('txtsalesHours')" />
                    </CCol>
                </CRow>
                <template #footer>
                    <CRow class="justify-content-around col-md-12">
                        <CCol col="4">
                            <CButton color="success" v-if="loadingSaveButton === false" @click="handlePopup" block>
                                {{ $t('save') }}
                            </CButton>
                            <CButton v-else-if="loadingSaveButton === true" color="success" block disabled>
                                <CSpinner color="white" size="sm" />
                            </CButton>
                        </CCol>
                        <CCol col="4">
                            <CButton v-if="loadingSaveButton === false" @click="addSalesHoursModal = false"
                                color="light" block>
                                {{ $t('cancel') }}
                            </CButton>
                            <CButton v-else-if="loadingSaveButton === true" color="light" block disabled>
                                {{ $t('cancel') }}
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
            </CModal>

            <CModal :show.sync="confrimSaveSalesHoursModal" :title="namemodal" centered>
                <CRow>
                    <CCol md="4" sm="4" class="mt-2">{{ $t('txtsalesHours') }}</CCol>
                    <CCol md="8" sm="8">
                        <CAlert color="danger" v-if="saveErrorMsg" :show="Number(1)">
                            {{ saveErrorMsg }}
                        </CAlert>
                        <CInput v-if="validateSellingname" v-model="sellingname" :placeholder="$t('txtsalesHours')"
                            :is-valid="validator" :invalid-feedback="$t('validateSellingname')" />
                        <CInput v-else v-model="sellingname" :placeholder="$t('txtsalesHours')" />
                    </CCol>
                </CRow>
                <template #footer>
                    <CRow class="justify-content-around col-md-12">
                        <CCol col="4">
                            <CButton color="success" v-if="loadingSaveButton === false" @click="handlePopup" block>
                                {{ $t('save') }}
                            </CButton>
                            <CButton v-else-if="loadingSaveButton === true" color="success" block disabled>
                                <CSpinner color="white" size="sm" />
                            </CButton>
                        </CCol>
                        <CCol col="4">
                            <CButton v-if="loadingSaveButton === false" @click="confrimSaveSalesHoursModal = false"
                                color="light" block>
                                {{ $t('cancel') }}
                            </CButton>
                            <CButton v-else-if="loadingSaveButton === true" color="light" block disabled>
                                {{ $t('cancel') }}
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
            </CModal>
            <CModal color="danger" :title="$t('confirmDeletSellingHours')" :show.sync="deleteModal" centered>
                <h4 class="mt-4 mb-4 text-center">{{ $t('dialigDeleteSellingHours') }}</h4>
                <template #footer>
                    <CRow class="justify-content-around col-md-12">
                        <CCol col="4">
                            <CButton v-if="loadingSaveButton === false" @click="deleteSellingHours(itemDelete)"
                                color="danger" block>
                                {{ $t('delete') }}
                            </CButton>
                            <CButton v-else-if="loadingSaveButton === true" color="danger" block disabled>
                                <CSpinner color="white" size="sm" />
                            </CButton>
                        </CCol>
                        <CCol col="4">
                            <CButton v-if="loadingSaveButton === false" @click="deleteModal = false" color="light"
                                block>
                                {{ $t('cancel') }}
                            </CButton>
                            <CButton v-else-if="loadingSaveButton === true" color="light" disabled block>
                                {{ $t('cancel') }}
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
            </CModal>
            <modal color="success" :show.sync="confirmSynchModal" :title="$t('confirmSynch')" v-on:isSave="synchronizeMenuwithGrab" centered
                    :alertError="alertError" :loading="loadingSyns">
                    <div v-if="alertError">
                        <h4 class="text-center text-danger">{{ textError }}</h4>
                        <br />
                        <h5 class="text-center text-danger">{{ textMessage }}</h5>
                        </div>
                        <div v-else>
                        <br />
                        <h4 class="text-center">{{ $t('confirmSynch') }}</h4>
                        <br />
                    </div>
            </modal>

        </div>
        <div id="manageSalesHoursGrabAccess" v-else>
            <access-grab></access-grab>
        </div>
        <!-- </div>
      <div id="manageSalesHoursNoPermission" v-else>
        <p id="manageSalesHoursNoPermissionMessage">{{ $t("noPermission") }}</p>
      </div> -->
    </div>
</template>



<script>
import { mapGetters } from 'vuex'
import util from '@/util/util'
import pos from '@/services/local'
import Pagination from '@/containers/Pagination'
import DataTable from "@/containers/DataTable";
import permis from '@/util/permission'
import VueTimepicker from "vue2-timepicker"
export default {
    components: {
        Pagination,
        VueTimepicker,
        DataTable
    },
    data() {
        return {

            synchSuccess : false,
            synchSuccessCounter : 0,
            status: "",                // Menu sync status
            statusCheckInterval: null ,// Interval for status checking
            synchStatusText: "",       // Text to display for sync status
            synchFailDescription : "",
            loadingSyns :  true,
            deleteModal: false,
            addSalesHoursModal: false,
            loadingSaveButton: false,
            loading: false,
            loadingButton: true,
            meta_data: {
                last_page: null,
                current_page: 1,
                prev_page_url: null,
                items: 0,
                limit: 25,
                itemCount: null,
            },
            validateSellingname: '',
            sellingname: '',
            namemodal: '',
            isGrabOrderEnabled: false,
            data: [],
            details: [],
            isSuccess: false,
            saveErrorMsg: '',
            confrimSaveSalesHoursModal: false,
            itemDelete: {},
            isDeleteSuccess: false,
            maxSlots: 3, // Max number of slots allowed per day
            confirmSynchModal : false,
            alertError: false,
        };
    },
    created() {
        this.getOrderShopSetting()
        this.getManageSaleHours();
    },
    computed: {
        ...mapGetters(['shops', 'users', 'isOwner']),
        statusColor() {
            if (this.status === 'QUEUEING') {
                return 'warning';
            } else if (this.status === 'PROCESSING') {
                return 'warning';
            } else if (this.status === 'SUCCESS') {
                return 'success';
            }else if (this.status === 'fail') {
                return 'danger';
            } else {
                return 'danger'; // Default color for unknown status
            } 
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },
        shop() {
            return this.shops.find((i) => i.objectId === this.shopObjectId)
        },
        isPermission() {
            let path = '/product'
            return permis.findPermissionRead('product', path)
        },
        isEditData() {
            let path = '/product'
            return permis.findPermissionEdit('product', path)
        },
        fields() {
            if (this.isEditData) {
                return [
                    { key: 'index', label: '#', _style: 'width:2%', _classes: 'text-dark font-weight-normal' },
                    { key: 'name', label: this.$i18n.t('txtsalesHours'), _classes: 'font-weight-normal text-dark' },
                    { key: 'action', label: '', _style: 'width:15%', _classes: 'text-dark font-weight-normal' },
                ]
            } else {
                return [
                    { key: 'index', label: '#', _style: 'width:2%', _classes: 'text-dark font-weight-normal' },
                    { key: 'name', label: this.$i18n.t('txtsalesHours'), _classes: 'font-weight-normal text-dark' },
                ]
            }
        },
        items() {
            return this.data.map((item, index) => {
                return {
                    id: item.id,
                    index: index + 1,
                    name: item.name,
                    sequence: item.sequence,
                    serviceHours: item.serviceHours,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    action: this.isEditData ? 'edit' : '',
                }
            })
        },
    },
    methods: {
        menuSyncStatus(){
            this.loadingSyns = false
            const shopObjectId = this.shopObjectId
            const headers = { 
                shopObjectId: shopObjectId 
            }
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'GET',
                headers :headers,
                params: params,
                url: '/api/v3.0/grab/menu/sync/status',
            })
            .then((res) => {
                if(res.status === 200){
                    this.status = res.data.data.status;

                    // Show alerts based on the status
                    if (this.status === 'QUEUEING') {
                        this.synchSuccessCounter  = 12
                        this.synchSuccess = false,
                        this.synchStatusText = this.$i18n.t('synchQueueing')  
                        // alert('The menu synchronization is currently in QUEUEING. Please wait...');
                    } else if (this.status === 'PROCESSING') {
                        this.synchSuccessCounter  = 12
                        this.synchSuccess = false,
                        this.synchStatusText = this.$i18n.t('synchProcessing')  
                        // alert('The menu synchronization is in progress. Please be patient.');
                    } else if (this.status === 'SUCCESS') {
                        this.synchSuccessCounter  = 5
                        this.synchSuccess = true,
                        this.synchStatusText = this.$i18n.t('synchSuccess')  

                        // alert('The menu synchronization is complete!');
                        // Stop the polling logic if needed
                        this.loadingSyns = true
                        clearInterval(this.syncInterval);
                    }else if  (this.status === 'FAILED') {
                        this.status = "fail";
                        this.synchSuccessCounter  = 5
                        this.synchStatusText = this.$i18n.t('synchFail') 
                        this.statusDescription = this.$i18n.t('synchFailDescription')
                        clearInterval(this.syncInterval);
                        // alert('An error occurred while checking the sync status. Please try again.');
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                this.status = "fail";
                this.synchSuccessCounter  = 5
                this.synchStatusText = this.$i18n.t('synchFail') 
                this.statusDescription = this.$i18n.t('synchFailDescription')
                // alert('An error occurred while checking the sync status. Please try again.');
            });
        },
        synchronizeMenuwithGrab(){
            this.loadingSyns = false
            this.uploadProgress = 60
            const shopObjectId = this.shopObjectId
            const headers = { 
                shopObjectId: shopObjectId 
            }
            let params = {
                shopObjectId: this.shopObjectId,
            }

            pos({
                method: 'POST',
                headers :headers,
                params: params,
                url: '/api/v3.0/grab/menu/sync',
            })
            .then((res) => {
                if(res.status === 200){
                    this.uploadProgress = 0
                    this.loadingSyns = true
                    this.confirmSynchModal = false
                    this.menuSyncStatus();
                    // Start polling to check sync status
                    this.startSyncCheck();
                }
            })
            .catch((error) => {
                console.error(error);
                this.status = "fail";
                this.synchSuccessCounter  = 5
                this.synchStatusText = this.$i18n.t('synchFail') 
                this.statusDescription = this.$i18n.t('synchFailDescription')
                // alert('Failed to initiate menu synchronization. Please try again.');
            })
        },
        startSyncCheck() {
            // Initialize polling every 10 seconds until status is SUCCESS
            this.syncInterval = setInterval(() => {
                this.menuSyncStatus();
                if (this.status === 'SUCCESS') {
                    clearInterval(this.syncInterval);
                }
            }, 10000); // 10 seconds
        },
        validateData(item, index) {

            // Check if the name is not empty
            if (!item.name || item.name.trim() === "") {
                alert(this.$t("validateNameRequired")); // Show an alert or error message for a missing name
                return false;
            }

            // Validate periods for overlapping times
            const days = Object.keys(item.serviceHours);
            for (const day of days) {
                const dayData = item.serviceHours[day];
                if (dayData.enable) {
                    const periods = dayData.periods;

                    // Sort periods by startTime
                    const sortedPeriods = periods.sort((a, b) => a.startTime.localeCompare(b.startTime));

                    // Check for overlap in sorted periods
                    for (let i = 0; i < sortedPeriods.length - 1; i++) {
                        const currentEndTime = sortedPeriods[i].endTime;
                        const nextStartTime = sortedPeriods[i + 1].startTime;

                        if (currentEndTime > nextStartTime) {
                            alert(this.$t("timeSlotOverlap")); // Show an alert for overlapping periods
                            return false;
                        }
                    }
                }
            }

            // If all validations pass, call the updateData function
            this.updateData(item, index);
        },
        getDays(day) {
            return this.$i18n.t(day.toLowerCase())
        },
        addSlot(item, index, day) {
            const maxSlots = this.maxSlots || 3; // Ensure maxSlots is defined
            const serviceHours = item.serviceHours[day];

            if (serviceHours.periods.length >= maxSlots) {
                alert(this.$t("maxSlotsReached")); // Show alert if max slots are reached
                return;
            }

            const minTime = "00:00"; // Define the minimum time
            const maxTime = "23:59"; // Define the maximum time

            let newStartTime = minTime; // Default start time
            if (serviceHours.periods.length > 0) {
                // Calculate the new start time as 1 minute after the last slot's end time
                const lastSlotEndTime = serviceHours.periods[serviceHours.periods.length - 1].endTime;
                const [lastHour, lastMinute] = lastSlotEndTime.split(":").map(Number);
                const newStartMinute = lastMinute + 1;
                const newStartHour = lastHour + Math.floor(newStartMinute / 60);
                newStartTime = `${String(newStartHour).padStart(2, "0")}:${String(newStartMinute % 60).padStart(2, "0")}`;
            }

            // Validate that the new start time is within range
            if (newStartTime >= maxTime) {
                alert(this.$t("noMoreTimeSlots")); // Alert if no more time slots can be added
                return;
            }

            const newSlot = {
                startTime: newStartTime,
                endTime: maxTime, // Set the end time to maxTime
            };

            // Validate overlap with existing slots
            const isOverlapping = serviceHours.periods.some((slot) => {
                return (
                    (newSlot.startTime >= slot.startTime && newSlot.startTime < slot.endTime) || // New start overlaps
                    (newSlot.endTime > slot.startTime && newSlot.endTime <= slot.endTime) || // New end overlaps
                    (newSlot.startTime <= slot.startTime && newSlot.endTime >= slot.endTime) // Complete overlap
                );
            });

            if (isOverlapping) {
                alert(this.$t("timeSlotOverlap")); // Show alert if overlap is detected
                return;
            }

            // Add the new slot
            serviceHours.periods.push(newSlot);
        },
        validateName(val) {

            return val ? val.length >= 1 : false
        },
        validator(val) {
            return val ? val.length >= 1 : false
        },
        removeSlot(item, index, day, slotIndex) {
            const serviceHours = item.serviceHours[day];
            serviceHours.periods.splice(slotIndex, 1); // Remove the selected slot
        },
        getOrderShopSetting() {
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`
            let params = {
                shopObjectId: shopObjectId,
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/shop/ordershopsetting",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {

                if (res.status == 200) {
                    let isGrabOrderEnabled = res.data.data.isGrabOrderEnabled
                    let openingHour = res.data.data.grab.openingHour
                    if (isGrabOrderEnabled !== undefined && isGrabOrderEnabled !== null && isGrabOrderEnabled !== '') {
                        this.isGrabOrderEnabled = isGrabOrderEnabled
                    } else {
                        this.isGrabOrderEnabled = false
                    }

                    if (openingHour !== undefined && openingHour !== null && openingHour !== '') {
                        this.openingHour = openingHour
                    } else {
                        this.openingHour = false
                    }

                } else {
                    this.isGrabOrderEnabled = false
                }

            }).catch((error) => {
                console.log(error);
            });
        },
        hours(index) {

            let data = this.data[index].serviceHours;

            this.orderDetail = data;
        },
        hoursName(index) {
            let data = util.getDayName(index);

            return data
        },
        handlePopup() {
            if (this.sellingname == '') {
                this.validateSellingname = 'กรุณากรอกหน่วยสินค้า'
            } else {
                this.validateSellingname = ''
                this.savedata()
            }
        },
        savedata() {
            this.isSuccess = false
            this.saveErrorMsg = ''
            this.loadingSaveButton = true

            const shopObjectId = this.shopObjectId
            const headers = { shopObjectId: shopObjectId }

            let data = {
                name: this.sellingname,
            }

            pos({
                method: 'post',
                url: '/api/v3.0/sellingchannel/sellingtime',
                data: data,
                headers: headers,
            }).then((res) => {

                if (res.status === 200) {
                    this.isSuccess = true
                    this.addSalesHoursModal = false
                    this.sellingname = ''
                    this.loadingSaveButton = false

                    // Push the new item into this.data
                    const newItem = res.data.data; // Assuming res.data.data is the new item
                    this.data.push({
                        ...newItem,
                        index: this.data.length + 1,
                        serviceHours: this.normalizeServiceHours(newItem.serviceHours),
                    });
                } else {
                    console.error("Failed to fetch sales hours data. Status:", res.status);
                    this.data = [];
                }
            })
                .catch((error) => {
                    this.loadingSaveButton = false

                    if (error.code === 'ECONNABORTED') {
                        this.saveErrorMsg = 'Request timed out'
                    } else {
                        this.saveErrorMsg = error.message
                    }
                })
                .finally(() => {
                    // Update loading states
                    this.loading = false;
                    this.loadingSaveButton = false
                });
        },
        updateData(item, index) {
            this.isSuccess = false
            this.saveErrorMsg = ''
            this.loadingSaveButton = true
            this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)

            const shopObjectId = this.shopObjectId
            const headers = { shopObjectId: shopObjectId }
            // Prepare the serviceHours object by removing "enable" and setting the appropriate "openPeriodType"
            const serviceHours = {};
            Object.keys(item.serviceHours).forEach((key) => {
                const dayData = item.serviceHours[key];
                serviceHours[key] = {
                    ...dayData,
                    openPeriodType: dayData.enable ? "OpenPeriod" : "CloseAllDay", // Set openPeriodType based on enable
                    periods: dayData.periods, // Retain periods as they are
                };
                delete serviceHours[key].enable; // Remove enable field
            });

            let data = {
                id: item.id,
                name: item.name,
                sequence: item.sequence,
                serviceHours: serviceHours,
                startTime: item.startTime,
                endTime: item.endTime
            }

            try {
                pos({
                    method: 'PATCH',
                    url: '/api/v3.0/sellingchannel/sellingtime',
                    data: data,
                    headers: headers,
                })
                this.isSuccess = true
                this.addSalesHoursModal = false
                this.sellingname = ''
                this.loadingSaveButton = false
                this.toggleDetails(item, index)

            } catch (error) {
                this.loadingSaveButton = false

                if (error.code === 'ECONNABORTED') {
                    this.saveErrorMsg = 'Request timed out'
                } else {
                    this.saveErrorMsg = error.message
                }
            }
        },
        toggleDetails(item, index) {
            const position = this.details.indexOf(item);
            this.hours(index);
            position !== -1
                ? this.details.splice(position, 1)
                : this.details.push(item);
        },
        confirmdelete(item) {
            this.deleteModal = true
            this.itemDelete = item
        },
        addSalseHours() {
            // this.$router.push('mangesaleshours/add');
            this.validateSellingname = ''
            this.itemId = ''
            this.itemObjectId = ''
            this.sellingname = ''
            this.namemodal = this.$i18n.t('addSalesHours')
            this.addSalesHoursModal = true
        },
        getManageSaleHours() {
            this.loading = true;
            this.loadingButton = false;

            const shopObjectId = this.shopObjectId; // Retrieve shopObjectId
            const headers = { shopObjectId }; // Set headers

            pos.get('/api/v3.0/sellingchannel/sellingtime', { headers })
                .then((res) => {

                    if (res.status === 200) {
                        let data = res.data.data
                        this.setSellingListData(data)

                    } else {
                        console.error("Failed to fetch sales hours data. Status:", res.status);
                        this.data = [];
                    }
                })
                .catch((error) => {
                    console.error("Error fetching sales hours data:", error);
                    this.data = [];
                })
                .finally(() => {
                    // Update loading states
                    this.loading = false;
                    this.loadingButton = true;
                });
        },
        setSellingListData(list) {
            // console.log(res.data);

            const data = list || []; // Ensure data is an array
            // Process and structure the data
            this.data = data.map((item, index) => {

                // Ensure serviceHours has a valid structure
                let enable = false;
                if (item.serviceHours?.mon?.openPeriodType === 'OpenPeriod') {
                    enable = true; // Enable for 'OpenPeriod' condition
                }

                item.serviceHours = {
                    mon: {
                        ...item.serviceHours?.mon,
                        openPeriodType: item.serviceHours?.mon?.openPeriodType || 'CloseAllDay',
                        enable: enable,
                        periods: item.serviceHours?.mon?.periods || []
                    },
                    tue: {
                        ...item.serviceHours?.tue,
                        openPeriodType: item.serviceHours?.tue?.openPeriodType || 'CloseAllDay',
                        enable: item.serviceHours?.tue?.openPeriodType === 'OpenPeriod',
                        periods: item.serviceHours?.tue?.periods || []
                    },
                    wed: {
                        ...item.serviceHours?.wed,
                        openPeriodType: item.serviceHours?.wed?.openPeriodType || 'CloseAllDay',
                        enable: item.serviceHours?.wed?.openPeriodType === 'OpenPeriod',
                        periods: item.serviceHours?.wed?.periods || []
                    },
                    thu: {
                        ...item.serviceHours?.thu,
                        openPeriodType: item.serviceHours?.thu?.openPeriodType || 'CloseAllDay',
                        enable: item.serviceHours?.thu?.openPeriodType === 'OpenPeriod',
                        periods: item.serviceHours?.thu?.periods || []
                    },
                    fri: {
                        ...item.serviceHours?.fri,
                        openPeriodType: item.serviceHours?.fri?.openPeriodType || 'CloseAllDay',
                        enable: item.serviceHours?.fri?.openPeriodType === 'OpenPeriod',
                        periods: item.serviceHours?.fri?.periods || []
                    },
                    sat: {
                        ...item.serviceHours?.sat,
                        openPeriodType: item.serviceHours?.sat?.openPeriodType || 'CloseAllDay',
                        enable: item.serviceHours?.sat?.openPeriodType === 'OpenPeriod',
                        periods: item.serviceHours?.sat?.periods || []
                    },
                    sun: {
                        ...item.serviceHours?.sun,
                        openPeriodType: item.serviceHours?.sun?.openPeriodType || 'CloseAllDay',
                        enable: item.serviceHours?.sun?.openPeriodType === 'OpenPeriod',
                        periods: item.serviceHours?.sun?.periods || []
                    },
                };

                return {
                    id: item.id,
                    index: index + 1,
                    name: item.name,
                    sequence: item.sequence,
                    serviceHours: item.serviceHours,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    action: this.isEditData ? 'edit' : '',
                };
            });
        },
        deleteSellingHours(item) {
            this.isDeleteSuccess = false
            this.loadingSaveButton = true
            const shopObjectId = this.shopObjectId
            const headers = { shopObjectId: shopObjectId }
            let data = {
                id: item.id,
            }

            pos({
                method: 'DELETE',
                url: '/api/v3.0/sellingchannel/sellingtime',
                data: data,
                headers: headers,
            })
                .then((res) => {
                    setTimeout(() => {
                        let data = res.data.data
                        this.isDeleteSuccess = true
                        this.deleteModal = false
                        this.addUnitModal = false
                        this.loadingSaveButton = false
                        this.setSellingListData(data)
                    }, 1000)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        normalizeServiceHours(serviceHours) {
            return {
                mon: {
                    ...serviceHours?.mon,
                    openPeriodType: serviceHours?.mon?.enable ? 'OpenPeriod' : 'CloseAllDay',
                    periods: serviceHours?.mon?.periods || [],
                },
                tue: {
                    ...serviceHours?.tue,
                    openPeriodType: serviceHours?.tue?.enable ? 'OpenPeriod' : 'CloseAllDay',
                    periods: serviceHours?.tue?.periods || [],
                },
                wed: {
                    ...serviceHours?.wed,
                    openPeriodType: serviceHours?.wed?.enable ? 'OpenPeriod' : 'CloseAllDay',
                    periods: serviceHours?.wed?.periods || [],
                },
                thu: {
                    ...serviceHours?.thu,
                    openPeriodType: serviceHours?.thu?.enable ? 'OpenPeriod' : 'CloseAllDay',
                    periods: serviceHours?.thu?.periods || [],
                },
                fri: {
                    ...serviceHours?.fri,
                    openPeriodType: serviceHours?.fri?.enable ? 'OpenPeriod' : 'CloseAllDay',
                    periods: serviceHours?.fri?.periods || [],
                },
                sat: {
                    ...serviceHours?.sat,
                    openPeriodType: serviceHours?.sat?.enable ? 'OpenPeriod' : 'CloseAllDay',
                    periods: serviceHours?.sat?.periods || [],
                },
                sun: {
                    ...serviceHours?.sun,
                    openPeriodType: serviceHours?.sun?.enable ? 'OpenPeriod' : 'CloseAllDay',
                    periods: serviceHours?.sun?.periods || [],
                },
            };
        },
    },
};
</script>

<style scoped>
.schedule-container {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.day-schedule {
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    padding: 1px;
    border: 0.9px solid;
    border-color: #E5E5E5;
    border-radius: 5px;
}

.day-schedule:hover {
    background: #f0f8ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-color: #d1d1d1;
}

.day-header {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
}

.add-btn {
    background: none;
    border: none;
    color: green;
    cursor: pointer;
}

.delete-btn {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
}

.vue-content-placeholders-heading__title {
    position: relative;
    overflow: hidden;
    height: 25px;
    width: 85%;
    margin-bottom: 10px;
}

.vue-content-placeholders-heading__subtitle {
    position: relative;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 25px;
    width: 90%;
}

.vue-content-placeholders-text__line {
    position: relative;
    overflow: hidden;
    height: 20px;
    width: 100%;
    margin-bottom: 10px;
}

/* word-wrap: break-word; */
</style>
